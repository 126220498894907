import { Backdrop, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  StyledButtonBookmark,
  StyledButtonLink,
  StyledCoverLoader,
  StyledIcon,
  StyledPlayerLoader,
  StyledStatusBoxPlayer,
  VideoStack
} from "./Video.styles";
import { VideoComponentProps } from "./Video.types";
import {
  // ButtonText,
  CtaOverlay,
  GridMain,
  Icon,
  // Scorm,
  ScormWrapper,
  StatusBox,
  VideoCaptionOverlay
} from "../../components";
import {
  LEVEL,
  STATUS_LO
} from "../../consts";
import { UserNavigationContext } from "../../context/UserNavigationContext";
import { useInvalidateStartOrResumeQuery } from "../../queries";
import { useNavigate } from "../../services";
import { useAuthStore } from "../../stores";


const EXTERNAL_LEVEL = {
  [LEVEL.DA_SCORM]: LEVEL.DA_SCORM,
  [LEVEL.DA_XAPI]: LEVEL.DA_XAPI,
  [LEVEL.DA_SURVEY]: LEVEL.DA_SURVEY,
  [LEVEL.DA_YOUTUBE]: LEVEL.DA_YOUTUBE,
  [LEVEL.DA_EXTERNAL_RES]: LEVEL.DA_EXTERNAL_RES
} as const;
type ExternalContent = keyof typeof EXTERNAL_LEVEL

export function VideoComponent({
  autoplay,
  borderRadius,
  courseDetails,
  courseId,
  coverFallBack,
  height,
  id,
  isFavourite,
  isMandatory,
  learningObjectType,
  learningObjectTypology,
  loStarted,
  moduleNumber,
  parentId,
  rating,
  rootDetails,
  refetchParentCourse,
  refetchParentRoot,
  shortDescription,
  status,
  title,
  videoId,
  tentativeId,
  isExternal,
  width = "100%",
  ...props
}: VideoComponentProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname, state: routerState } = useLocation();
  const { t } = useTranslation();
  const [autoplaySet, setAutoPlaySet] = useState(autoplay);
  const [buttonSelected, setButtonSelected] = useState(true);
  const [externalIsOpen, setExternalIsOpen] = useState<ExternalContent | null>(null);
  const [triggerPausePlayer, setTriggerPausePlayer] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(isExternal);
  const { navigation } = useContext(UserNavigationContext);

  const b2cFlag = useAuthStore(state => state.corporateInfo?.b2c_flag);

  const { invalidateQueryStartOrResume } = useInvalidateStartOrResumeQuery({
    learningObjectId: loStarted.id.toString(),
    learningObjectTypology: loStarted.learningObjectTypology,
    parentId: loStarted.parentId ? loStarted.parentId.toString() : undefined
  });

  // const isExternal =
  //   learningObjectTypology === EXTERNAL_LEVEL.DA_SCORM
  //   || learningObjectTypology === EXTERNAL_LEVEL.DA_XAPI
  //   || learningObjectTypology === EXTERNAL_LEVEL.DA_SURVEY
  //   || learningObjectTypology === EXTERNAL_LEVEL.DA_YOUTUBE;

  const isFromYoutube = learningObjectTypology === EXTERNAL_LEVEL.DA_YOUTUBE;
  const isExternalRes = learningObjectTypology === EXTERNAL_LEVEL.DA_EXTERNAL_RES;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const uri = `${window.location.origin}/esplora/scorm/${id}/${learningObjectTypology}`;
  // const params = `courseId=${courseId ?? null}&rootId=${parentId ?? null}`;

  // const handleSaveCourse = () => {
  //   setOpenBackdrop(false);
  // };
  useEffect(() => {
    if (openBackdrop === true) {
      document.body.style.overflow = "hidden";
    }
  }, [openBackdrop]);

  useEffect(() => {
    window.onpopstate = () => {
      document.body.removeAttribute("style");
    };
  }, []);
  
  window.addEventListener("message", (e) => {
    if (e.data == "closeCoursesModal") {
      document.body.removeAttribute("style");
      setOpenBackdrop(false);

      let rootCalculatedId: number | null = rootDetails?.learningObjectTypology === "PATH" ? rootDetails.id : null;
      let courseCalculatedId: number | null = null;

      if (rootDetails?.learningObjectTypology === "COURSE" && rootDetails.isStandAlone) {
        courseCalculatedId = rootDetails.id;
        rootCalculatedId = null;
      }

      if (!navigation?.length) return;
      const isForYou = pathname.includes("per-te");
      const isEsplora = pathname.includes("esplora");
      
      if (isForYou) {
        navigate(`/per-te/dettaglio/${id}/${learningObjectTypology}`, {
          state: { courseId: routerState?.courseId || courseCalculatedId || courseDetails?.id, rootId: routerState?.rootId || rootCalculatedId }
        });
      }

      if (isEsplora) {
        navigate(`/esplora/dettaglio/${id}/${learningObjectTypology}`, {
          state: { courseId: routerState?.courseId || courseCalculatedId || courseDetails?.id, rootId: routerState?.rootId || rootCalculatedId }
        });
      }
    }
  });  
    
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const handleMouseLeave = () => {
      if (!document.hidden){
        setTriggerPausePlayer(true);
      }
    };

    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <VideoStack
        $isExternal={ isExternal }
        height={ isExternal ? height : isMobile ? "100%" : height }
        width={ width }
        { ...props }
      >
        {
          (isExternal || !Number(videoId)) ? (
            <StyledCoverLoader
              minHeight={ "750px" }
              position="relative"
              $cover={ externalIsOpen ? undefined : coverFallBack }
              $videoVisible={ Boolean(externalIsOpen) }
              sx={ {
                [`${theme.breakpoints.down("sm")}`]: {
                  minHeight: "450px"
                }
              } }
            >
              <GridMain
                margin={ "0 auto" }
                minHeight={ "750px" }
                position={ "absolute" }
                sx={ {
                  inset: 0,
                  [`${theme.breakpoints.down("sm")}`]: {
                    minHeight: "450px"
                  }
                } }
              >
                <StyledButtonBookmark
                  aria-label={ t("activities_show") }
                  disableRipple
                  $isFromYoutube={ isFromYoutube }
                  onClick={ () => { showTabs() } }
                  $selected={ buttonSelected }
                  sx={ { "&:hover": { border: "none" } } }
                >
                  <div></div>
                </StyledButtonBookmark>
                {
                  status === STATUS_LO.C ? (
                    <StyledStatusBoxPlayer>
                      <StatusBox
                        accent={ theme.customColors.systemSecondary02 }
                        icon={ "completato" }
                      />
                    </StyledStatusBoxPlayer>
                  ) : null
                }
                <StyledButtonLink
                  onClick={ () => { navigate(-1) } }
                  sx={ { padding: 0 } }
                >
                  <StyledIcon
                    icon={ "arrow_left" }
                    height={ "20px" }
                    width={ "16px" }
                  />
                  { t("back") }
                </StyledButtonLink>

                {
                  !externalIsOpen ? (
                    <VideoCaptionOverlay
                      courseDetails={ courseDetails }
                      learningObjectType={ learningObjectType }
                      learningObjectTypology={ learningObjectTypology }
                      moduleNumber={ moduleNumber }
                      rootDetails={ rootDetails }
                      title={ title }
                    />
                  ) : null
                }
                <CtaOverlay
                  id={ id }
                  isFavourite={ isFavourite }
                  learningObjectTypology={ learningObjectTypology }
                  rating={ rating }
                  share={ true }
                  courseId={ Number(courseId) }
                  rootId={ Number(parentId) }
                />
                {
                  !externalIsOpen ? (
                    <Box
                      className={ "icon-play" }
                      sx={ { pointerEvents: "auto" } }
                      onClick={ () => {
                        if (isExternal) {
                          // window.open(`${uri}?${params}`, "_blank", "noreferrer");
                          setOpenBackdrop(true);
                        }
                        else if (isFromYoutube) {
                          setExternalIsOpen(learningObjectTypology);
                        }

                      } }
                    >
                      <Icon icon={ "Icons_play" } size={ 25 } />
                    </Box>
                  ) : null
                }

              </GridMain>

              {
                externalIsOpen && isFromYoutube ?
                  <ScormWrapper
                    activityId={ id }
                    // close={ () => setExternalIsOpen(null) }
                    courseId={ courseId }
                    // fullScreen={ externalIsOpen !== EXTERNAL_LEVEL.DA_YOUTUBE }
                    isOpen={ Boolean(externalIsOpen) }
                    learningObjectTypology={ learningObjectTypology }
                    parentId={ parentId }
                  /> : null
              }

            </StyledCoverLoader>
          ) : (
            <StyledPlayerLoader
              autoplay={ autoplaySet }
              borderRadius={ borderRadius?.toString() }
              canPlayBack={ (status !== STATUS_LO.C && isMandatory) || Boolean(b2cFlag) }
              courseId={ courseId }
              coverFallBack={ coverFallBack }
              id={ id }
              onEndAction={ () => {
                setTimeout(() => {
                  invalidateQueryStartOrResume();
                  setAutoPlaySet(loStarted.id !== id);
                  if (rootDetails?.id) {
                    refetchParentRoot();
                  }
                  if (courseDetails?.id) {
                    refetchParentCourse();
                  }
                }, 300);

              } }
              parentId={ parentId }
              position="relative"
              shortDescription={ shortDescription }
              title={ title }
              triggerPause={ triggerPausePlayer }
              videoId={ videoId }
              tentativeId={ tentativeId }
            // $cover={ coverFallBack }
            >
              {
                <GridMain
                  margin={ "0 auto" }
                  height={ "100%" }
                  position={ "absolute" }
                  sx={ { inset: 0 } }
                >
                  {
                    status === "C" ? (
                      <StyledStatusBoxPlayer>
                        <StatusBox
                          accent={ theme.customColors.systemSecondary02 }
                          icon={ "completato" }
                        />
                      </StyledStatusBoxPlayer>
                    ) : null
                  }
                  <StyledButtonBookmark
                    aria-label={ t("activities_show") }
                    disableRipple
                    $isFromYoutube={ false }
                    onClick={ () => { showTabs() } }
                    $selected={ buttonSelected }
                    sx={ { "&:hover": { border: "none" } } }
                  >
                    <div></div>
                  </StyledButtonBookmark>
                  <StyledButtonLink
                    onClick={ () => {
                      setTriggerPausePlayer(true);
                      navigate(-1);
                    } }
                    sx={ { padding: 0 } }
                  >
                    <StyledIcon
                      icon={ "arrow_left" }
                      width={ "16px" }
                      height={ "20px" }
                    />
                    { t("back") }
                  </StyledButtonLink>
                  <VideoCaptionOverlay
                    courseDetails={ courseDetails }
                    learningObjectType={ learningObjectType }
                    learningObjectTypology={ learningObjectTypology }
                    rootDetails={ rootDetails }
                    title={ title }
                  />
                  <CtaOverlay
                    id={ id }
                    isFavourite={ isFavourite }
                    learningObjectTypology={ learningObjectTypology }
                    rating={ rating }
                    share={ true }
                    courseId={ Number(courseId) }
                    rootId={ Number(parentId) }
                  />
                </GridMain>
              }
            </StyledPlayerLoader>
          )
        }
      </VideoStack>
      { isExternalRes ? 
        <></> : 
        openBackdrop && 
          <Backdrop
            sx={ { background: "rgba(255,255,255,0.3)", padding: 4, zIndex: 1500 } }
            open={ openBackdrop }
          >
            { /* <button onClick={ () => backNavigation() }>Indietro</button> */ }
            <Box sx={ {
              background: "#000",
              height: "100%",
              position: "relative",
              width: "100%"
            } }>
              <ScormWrapper
                activityId={ id }
                // close={ () => setExternalIsOpen(null) }
                courseId={ routerState?.courseId || courseId }
                // fullScreen={ externalIsOpen !== EXTERNAL_LEVEL.DA_YOUTUBE }
                isOpen={ Boolean(openBackdrop) }
                learningObjectTypology={ learningObjectTypology }
                parentId={ parentId }
              />
            </Box>
          </Backdrop>
      }
    </>
  );

  function showTabs() {
    const $tabsComponent = document.querySelector(".tab-component") as HTMLDivElement;
    if ($tabsComponent) {
      if (buttonSelected) {
        setButtonSelected(false);
        $tabsComponent.classList.remove("is-visible");
      } else {
        setButtonSelected(true);
        $tabsComponent.classList.add("is-visible");
      }
    }
  }
}
