/* eslint-disable */
import {
  Avatar,
  Box,
  Container,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import Popover from "@mui/material/Popover";
import {
  alpha,
  styled
} from "@mui/material/styles";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import DOMPurify from "dompurify";
import {
  Fragment,
  useState,
  useEffect,
  useMemo,
  type Dispatch,
  type SetStateAction
} from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import {
  CancelIcon,
  CancelIconWrapper,
  SearchInputBase,
  Search,
  SearchIcon,
  SearchIconWrapper,
  StylesresultsSearchMobile,
  ChatBotIcon
} from "./Header.styles";
import { TypeLevel } from "./TypeLevel.component";
import chatBotIcon from "../../assets/chatBotIcon.svg";
import logoFallback from "../../assets/digited_logo.png";
import logoMobileFallback from "../../assets/logoMobile.png";
import {
  ChatBot,
  Button,
  Icon,
  LanguageModal,
  Link,
  Logo,
  Notification
} from "../../components";
import { ImpersonationModal } from "../../components/ImpersonationModal";
import { NAVBAR_ITEMS, PERMISSIONS } from "../../consts";
import {
  useGetProfileImageQuery,
  useNotificationMutation,
  useNotificationQuery,
  useSearchPreviewQuery,
  type Notification as NotificationType
} from "../../queries";
import {
  useAuthStore,
  useLanguageStore,
  useNavbarStore,
  useSearchStore
} from "../../stores";
import type {
  ContentType,
  Level,
} from "../../types";
import { stringAvatar, getUserAvatar } from "../../utils/avatar";
import { getEnvironmentVariables, getCookieByName } from "../../utils/general";
import { ResultsStack } from "../SelectInput/SelectInput.styles";
import { t } from "i18next";

const { adminLink } = getEnvironmentVariables();

const DROPDOWN = {
  CHATBOT: "CHATBOT",
  MENU: "MENU",
  NOTIFICATIONS: "NOTIFICATIONS",
  SEARCH: "SEARCH"
} as const;
type Dropdown = keyof typeof DROPDOWN

export function Header({ authenticated=false, showTutorialUserMenu, isMultilanguage, languages }: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userData = useAuthStore(state => state.userData);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const localUser = localStorage.getItem("authState"); 
  const localUsername = localUser && JSON.parse(localUser || "")?.user.username;
  const isSSO = localUser && JSON.parse(localUser || "")?.mode === "SSO";
  const isImpersonation = isSSO ? localUsername?.toLowerCase().split("_")[1] !== userData?.external_id.toLowerCase() :  localUsername?.toLowerCase() !== userData?.external_id.toLowerCase();

  /* move to store/context */
  const [anchorElDropdown, setAnchorElDropdown] = useState<HTMLElement | null>(null);
  const [anchorElNotify, setAnchorElNotify] = useState<HTMLElement | null>(null);
  const [dropdown, setDropdown] = useState<Dropdown | null>(null);
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false); // TODO: can this become local?
  const [searchValue, setSearchValue] = useState("");
  /* move to store/context */
  const [langaugeModalOpen, setLanguageModalOpen] = useState(false);
  const [impersonationModalOpen, setImpersonationModalOpen] = useState(false);

  useWrapper({
    hideDropdowns: () => { setDropdown(null) },
    setSearchValue,
    showSearchResults: dropdown === DROPDOWN.SEARCH
  });

  useEffect(() => {
    if (!!showTutorialUserMenu) {
      setDropdown(DROPDOWN.MENU)
    } else {
      setDropdown(null)
    }

  }, [showTutorialUserMenu])

  return !isMobile ? (
    <HeaderAppBar $mobileSearchOpen={ mobileSearchOpen } id="app-bar">
      <CloseDropdowns closeDropdowns={ () => { setDropdown(null) } }/>
      <ChatbotDrawer
        hideChatbot={ () => setDropdown(null) }
        showChatBot={ dropdown === DROPDOWN.CHATBOT }
      />
      {
        authenticated ?
          <NotificationsPopOver
            anchorElNotify={ anchorElNotify }
            hideNotifications={ () => setDropdown(null) }
            setAnchorElNotify={ setAnchorElNotify }
            showNotifications={ dropdown === DROPDOWN.NOTIFICATIONS }
          /> :
          null
      }

      <Container
        maxWidth="max"
        sx={ { "& .MuiToolbar-root": { paddingLeft: "18px" } } }
      >
        <Toolbar sx={ { p: "0 18px" } }>
          { /* left side elements */ }
          <LinkLogo authenticated={ authenticated  }/>
          { /* center elements */ }
          {
            // search
            authenticated  ? (
              <SearchBox
                setDropdown={ setDropdown }
                searchValue={ searchValue }
                setSearchValue={ setSearchValue }
                showSearchResults={ dropdown === DROPDOWN.SEARCH }
              />
            ) : null
          }
          { /* right side elements */ }
          {
            authenticated  ? (
              <Fragment>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={ 3 }
                  sx={ { justifyContent: "flex-end" } }
                >
                  { /* chatboticon */ }
                  { !!localUser && isImpersonation && 
                    <Tooltip title={t("end-impersonation-title")}>
                      <IconButton
                        sx={{
                          cursor: "pointer",
                          zIndex: "1050"
                        }}
                        onClick={ () => setImpersonationModalOpen(true) }
                      >
                        <Icon
                          icon={ "almost_there" }
                          size={"1.5rem"}
                          color={theme.customColors.white}
                        />
                      </IconButton>
                    </Tooltip>
                  }
                  {
                    (smartConfiguration?.chatbot) ? (
                      <ChatBotIcon
                        onClick={ ()=> { setDropdown(DROPDOWN.CHATBOT) } }
                        zIndex={ theme.zIndex.fab }
                        id="chatbot"
                      >
                        <img alt="chatBot" src={ chatBotIcon } />
                      </ChatBotIcon>
                    ) : null
                  }
                  { /* notifications icon */ }
                  <NotificationsIcon
                    showNotifications={ dropdown === DROPDOWN.NOTIFICATIONS }
                    setAnchorElNotify={ setAnchorElNotify }
                    setDropdown={ setDropdown }
                  />
                  { /* avatar group */ }
                  <Grid
                    display={ "flex" }
                    justifyContent={ "space-between" }
                    gap={ 1 }
                    zIndex={ theme.zIndex.fab }
                  >
                    <ProfileIcon />
                    <MenuIcon
                      showDropdownMenu={ dropdown === DROPDOWN.MENU }
                      setAnchorElDropdown={ setAnchorElDropdown }
                      setDropdown={ setDropdown }
                    />
                  </Grid>
                </Box>
                { /* dropdown menu */ }
                <DropdownMenu
                  setLanguageModalOpen={ setLanguageModalOpen }
                  isMultilanguage={ Boolean(isMultilanguage) }
                  anchorElDropdown={ anchorElDropdown }
                  hideMenu={ () => setDropdown(null) }
                  showChatBot={ () => setDropdown(DROPDOWN.CHATBOT) }
                  showDropdownMenu={ dropdown === DROPDOWN.MENU }
                />
              </Fragment>
            ) : null
          }
          {
            !authenticated && isMultilanguage ? (
              <LanguageSelector setOpenLanguageModal={ setLanguageModalOpen } />
            ) : null
          }
        </Toolbar>
      </Container>
      <LanguageModal
        close={ () => {setLanguageModalOpen(false)} }
        languages={ languages ?? [] }
        open={ langaugeModalOpen }
      />
      <ImpersonationModal
        close={ () => setImpersonationModalOpen(false) }
        open={ impersonationModalOpen }
      />
    </HeaderAppBar>
  ) : (
    <HeaderAppBar $mobileSearchOpen={ mobileSearchOpen }>
      <CloseDropdowns closeDropdowns={ () => { setDropdown(null) } }/>
      <ChatbotDrawer
        hideChatbot={ () => setDropdown(null) }
        showChatBot={ dropdown === DROPDOWN.CHATBOT }
      />
      {
        mobileSearchOpen ?
          <MobileSearchDrawer
            mobileSearchOpen={ mobileSearchOpen }
            searchValue={ searchValue }
            setMobileSearchOpen={ setMobileSearchOpen }
            setSearchValue={ setSearchValue }
          /> : null

      }

      {
        authenticated  ?

          <NotificationsDrawer
            hideNotification={ () => setDropdown(null) }
            showNotifications={ dropdown === DROPDOWN.NOTIFICATIONS }
          /> : null
      }

      <Container maxWidth="max" sx={ { "& .MuiToolbar-root": { paddingLeft: isMobile? "0" : "18px" } } }>
        <Toolbar sx={ { minHeight: "auto", p: "0" } }>
          { /* left side elements */ }
          <LinkLogo authenticated={ authenticated }/>
          { /* right side elements */ }
          {
            authenticated  ? (
              <Fragment>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={ 3 }
                  sx={ { justifyContent: "flex-end", width: "100%" } }
                >
                  { /* search icon (mobile) */ }
                  <Button
                    className={ "search-opener" }
                    disableRipple
                    onClick={ () => { setMobileSearchOpen(true) } }
                    sx={ {
                      minWidth:"unset",
                      padding: "0",
                      zIndex: theme.zIndex.fab
                    } }
                  >
                    <SearchIcon
                      icon={ "Icons_search" }
                      size={ "20px" }
                    />
                  </Button>
                  { /* notifications icon */ }
                  <NotificationsIcon
                    showNotifications={ dropdown === DROPDOWN.NOTIFICATIONS }
                    setDropdown={ setDropdown }
                  />
                  { /* avatar group */ }
                  <Grid
                    display={ "flex" }
                    justifyContent={ "space-between" }
                    gap={ 1 }
                    zIndex={ theme.zIndex.fab }
                  >
                    <ProfileIcon />
                    <MenuIcon
                      showDropdownMenu={ dropdown === DROPDOWN.MENU }
                      setAnchorElDropdown={ setAnchorElDropdown }
                      setDropdown={ setDropdown }
                    />
                  </Grid>
                </Box>
                { /* dropdown menu */ }
                <DropdownMenu
                  setLanguageModalOpen={ setLanguageModalOpen }
                  isMultilanguage={ Boolean(isMultilanguage) }
                  anchorElDropdown={ anchorElDropdown }
                  hideMenu={ () => setDropdown(null) }
                  showChatBot={ () => setDropdown(DROPDOWN.CHATBOT) }
                  showDropdownMenu={ dropdown === DROPDOWN.MENU }
                  showTutorialUserMenu={showTutorialUserMenu}
                />
              </Fragment>
            ) : null
          }
          {
            !authenticated && isMultilanguage ? (
              <LanguageSelector
                setOpenLanguageModal={ setLanguageModalOpen } />
            ) : null
          }
        </Toolbar>
      </Container>
      <LanguageModal
        close={ () => setLanguageModalOpen(false) }
        languages={ languages ?? [] }
        open={ langaugeModalOpen }
      />
    </HeaderAppBar>
  );
}

// TODO: farmi spiegare da Robi wtf, needed on mobile? setShowSearchResults?
function useWrapper({
  hideDropdowns,
  setSearchValue,
  showSearchResults
} : {
  hideDropdowns: () => void
  setSearchValue: Dispatch<SetStateAction<string>>
  showSearchResults: boolean
}) {
  const wrapper = document.querySelector("#root .mask");
  wrapper?.addEventListener("click", () => {
    if (showSearchResults) {
      wrapper?.classList.remove("has-mask");
      hideDropdowns();
    }
  });

  useEffect(() => {
    if (showSearchResults) {
      wrapper?.classList.add("has-mask");
    } else {
      setSearchValue("");
      wrapper?.classList.remove("has-mask");
    }
  }, [wrapper, showSearchResults, setSearchValue]);
}

function NotificationsDrawer({
  hideNotification,
  showNotifications
} : {
  hideNotification: () => void
  showNotifications: boolean
}) {
  const { data: userNotifications } = useNotificationQuery({});
  const { mutate: postNotification } = useNotificationMutation();

  return (
    <Drawer
      anchor="right"
      hideBackdrop
      variant={ "temporary" }
      open={ showNotifications }
      onClose={ hideNotification }
      ModalProps={ { keepMounted: true } } // Better open performance on mobile.
      sx={ {
        "& .MuiDrawer-paper": {
          bottom: "0",
          boxSizing: "border-box",
          height: "calc(100% - 124px)",
          //top: "90px",
          top: "64px",
          width: "100%"
        }
      } }
    >
      <Notification
        contents={ userNotifications }
        onClick={ clickNotification }
        onClickLink={ () => hideNotification() }
      />
    </Drawer>
  );

  function clickNotification(notification: NotificationType) {
    if (notification.isRead !== "Y") {
      postNotification({
        messagesId: notification.eventSentId,
        status: "Y"
      });
    }
  }
}

const StyledPopover = styled(Popover)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,

  ".MuiPopover-paper": {
    border: `1px solid ${theme.customColors.border} !important`,
    borderRadius: `${theme.spacing(1)} !important`
  }
}));

function NotificationsPopOver({
  anchorElNotify,
  hideNotifications,
  setAnchorElNotify,
  showNotifications
} : {
  anchorElNotify: HTMLElement | null
  hideNotifications: () => void
  setAnchorElNotify: Dispatch<SetStateAction<HTMLElement | null>>
  showNotifications: boolean
}) {
  const { data: userNotifications } = useNotificationQuery({});
  const { mutate: postNotification } = useNotificationMutation();

  return (
    <StyledPopover
      anchorEl={ anchorElNotify }
      anchorOrigin={ {
        horizontal: 20,
        vertical: "bottom"
      } }
      classes={ { paper:"scrollable" } }
      className={ "popover-notification" }
      id={ showNotifications ? "simple-popover" : undefined }
      onClose={ () => {
        hideNotifications();
        setAnchorElNotify(null);
      } }
      open={ Boolean(showNotifications) }
      transformOrigin={ {
        horizontal: "right",
        vertical: -20
      } }
    >
      <Notification
        contents={ userNotifications }
        onClick={ clickNotification }
        onClickLink={ () =>  hideNotifications() }
      />
    </StyledPopover>
  );

  function clickNotification(notification: NotificationType) {
    if (notification.isRead !== "Y") {
      postNotification({
        messagesId: notification.eventSentId,
        status: "Y"
      });
    }
  }
}

function MobileSearchDrawer({
  mobileSearchOpen,
  searchValue,
  setMobileSearchOpen,
  setSearchValue
} : {
  mobileSearchOpen: boolean
  searchValue: string
  setMobileSearchOpen: Dispatch<SetStateAction<boolean>>
  setSearchValue: Dispatch<SetStateAction<string>>
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname, state: routerState } = useLocation();
  const navigate = useNavigate();

  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const latestSearch = useSearchStore((state) => state.latestSearch);
  const pushSearch = useSearchStore((state) => state.pushSearch);

  const [mobileShowResults, setMobileShowResults] = useState(false);

  const { data: results } = useSearchPreviewQuery({ freeText: encodeURIComponent(searchValue) });

  return (
    <Drawer
      hideBackdrop={ true }
      className="drawer-search-mobile"
      anchor="right"
      variant={ mobileShowResults ? "permanent" : "temporary" }
      open={ mobileSearchOpen }
      onClose={ () => setMobileSearchOpen(false) }
      // ModalProps={ {
      // // TODO: CAREFUL with keepMounted, the drawer queries are supposed to "refetchOnMount"
      //   keepMounted: true // Better open performance on mobile.
      // } }
      sx={ {
        ".MuiDrawer-paper": {
          boxSizing: "border-box",
          maxHeight: mobileShowResults? "61px" : "100%",
          width: "100%",
          zIndex: theme.zIndex.appBar
        }
      } }
    >
      <Box
        sx={ {
          alignItems: "center",
          borderBottom: `1px solid ${theme.customColors.border}`,
          display: "flex",
          gap: "24px",
          justifyContent: "space-between",
          padding: "10px 20px"
        } }
      >
        <Link href={ routerState?.previous ?? "/" }>
          <Icon
            color={ theme.customColors.systemPrimary01 }
            icon={ "arrow_left" }
            width={ "20px" }
            height={ "16px" }
            onClick={ () => {
              setMobileSearchOpen(false);
              setMobileShowResults(false);
            } }
          />
        </Link>
        <Search
          sx={ {
            maxWidth: "unset",
            width: "100%",
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              padding: "8px 16px"
            }
          } }
        >
          <SearchIconWrapper onClick={ () => {
            getSearchResults();
            setMobileShowResults(true);
            setMobileSearchOpen(true);
          } }>
            <Icon
              color={ theme.customColors.systemPrimary02 }
              icon={ "Icons_search" }
              width={ "24px" }
              height={ "24px" }
            />
          </SearchIconWrapper>
          <SearchInputBase
            value={ searchValue }
            onChange={ (changeEvent) => setSearchValue(changeEvent.target.value) }
            inputProps={ { "aria-label": "search" } }
            onKeyUp={ (keyboardEvent) => {
              if (keyboardEvent.code === "Enter") {
                pushSearch({
                  id: null,
                  learningObjectType: null,
                  learningObjectTypology: null,
                  title: (keyboardEvent.target as HTMLInputElement).value
                });
                navigate(
                  `/search/${(keyboardEvent.target as HTMLInputElement).value}`,
                  { state: { previous: pathname } }
                );
                //setNavbarItem("");
                setMobileSearchOpen(false);
                setMobileShowResults(true);
              }
            } }
            placeholder={ `${t("what_to_learn")}?` }
          />
          <CancelIconWrapper
            aria-label="toggle password visibility"
            onClick={ () => setSearchValue("") }>
            {
              searchValue ? (
                <Icon
                  icon={ "close" }
                  width={ "20px" }
                  height={ "20px" }
                  color={ theme.customColors.systemPrimary02 }
                />
              ) : null
            }
          </CancelIconWrapper>
        </Search>
      </Box>
      {
        !mobileShowResults ? (
          <StylesresultsSearchMobile>
            { /* has no search value and has previous search */ }
            {
              (searchValue.length === 0 && latestSearch?.length === 0) ? (
                <Typography
                  color={ theme.palette.text?.secondary }
                  fontSize="0.75rem"
                  fontWeight={ "700" }
                  lineHeight={ 1 }
                >
                  { t("no_recent_search") }
                </Typography>
              ) : null
            }
            { /* has no search value and has no previous search */ }
            {
              searchValue.length === 0 && latestSearch?.length > 0 ? (
                <Fragment>
                  <Typography
                    color={ theme.palette.text?.secondary }
                    fontSize="0.75rem"
                    fontWeight={ "700" }
                    lineHeight={ 1 }
                  >
                    { t("latest_research") }
                  </Typography>
                  {
                    latestSearch?.map(({
                      id,
                      learningObjectType,
                      learningObjectTypology,
                      title
                    }, idx) => (
                      <Link
                        key={ `search-result--${idx}` }
                        href={
                          Number(id) > 0
                            ? `/esplora/dettaglio/${id}/${learningObjectTypology}`
                            : `/search/${title}`
                        }
                        state={ { previous: pathname } }
                        onClick={ () => {
                          setMobileSearchOpen(false);
                          setMobileShowResults(true);
                        } }
                      >
                        <Box
                          display={ "flex" }
                          flexDirection={ "column" }
                          gap={ 1 }
                          onClick={ () => {
                            setSearchValue(title);
                            setNavbarItem(
                              Number(id) > 0
                                ? NAVBAR_ITEMS.EXPLORE
                                : NAVBAR_ITEMS.NONE
                            );
                          } }
                          sx={ {
                            cursor: "pointer",

                            ":hover": {
                              boxShadow:
                              /* eslint-disable max-len */
                                `0 0 20px ${theme.palette.primary?.main && alpha(theme.palette.primary?.main, 0.15)},
                                inset 0 0 40px ${theme.palette.primary?.main && alpha(theme.palette.primary?.main, 0.15)}`
                              /* eslint-enable max-len */
                            }
                          } }
                        >
                          {
                            (learningObjectType !== null && learningObjectTypology !== null) ? (
                              <TypeLevel
                                type={ learningObjectType as ContentType }
                                learningObjectTypology={ learningObjectTypology as Level }
                              />
                            ) : null
                          }
                          <Typography
                            color="black"
                            lineHeight={ 1 }
                            fontWeight={ 700 }
                            sx={ {
                              [theme.breakpoints.down("sm")]: {
                                color: theme.customColors.white,
                                textTransform: "capitalize"
                              }
                            } }
                          >
                            { title }
                          </Typography>
                        </Box>
                      </Link>
                    ))
                  }
                </Fragment>
              ) : null
            }
            { /* has search value and has no results */ }
            {
              searchValue.length > 0 && results?.length === 0 ? (
                <Typography
                  color={ theme.palette.text?.secondary }
                  fontSize="0.75rem"
                  fontWeight={ "700" }
                  lineHeight={ 1 }
                >
                  { t("no_results") + `"${searchValue}"` }
                </Typography>
              ) : null
            }
            { /* has search value (implicit) and has results */ }
            {
              results?.map(({
                id,
                learningObjectType,
                title,
                type
              }, idx) => (
                <Link
                  key={ `search-result--${idx}` }
                  href={ `/esplora/dettaglio/${id}/${type}` }
                  state={ {
                    previous: pathname
                  } }
                  onClick={ ()=>{
                    setMobileShowResults(true);
                    setMobileSearchOpen(false);
                  } }>
                  <Box
                    display={ "flex" }
                    flexDirection={ "column" }
                    gap={ 1 }
                    onClick={ () => {
                      setSearchValue(title);
                      pushSearch({
                        id,
                        learningObjectType: learningObjectType,
                        learningObjectTypology: type,
                        title: title
                      });
                      setNavbarItem(NAVBAR_ITEMS.EXPLORE);
                    } }
                    sx={ {
                      cursor: "pointer",

                      ":hover": {
                        boxShadow:
                        /* eslint-disable max-len */
                                      `0 0 20px ${theme.palette.primary?.main && alpha(theme.palette.primary?.main, 0.15)},
                                      inset 0 0 40px ${theme.palette.primary?.main && alpha(theme.palette.primary?.main, 0.15)}`
                      /* eslint-enable max-len */
                      }
                    } }
                  >
                    <TypeLevel
                      type={ learningObjectType }
                      learningObjectTypology={ type }
                    />
                    <HighlightedResult
                      searchValue={ searchValue }
                      title={ title }
                    />
                  </Box>
                </Link>
              ))
            }
          </StylesresultsSearchMobile>
        ) : null
      }
    </Drawer>
  );

  function getSearchResults () {
    if (searchValue.length > 0){
      pushSearch({
        id: null,
        learningObjectType: null,
        learningObjectTypology: null,
        title: searchValue
      });
      navigate(
        `/search/${encodeURIComponent(searchValue)}`,
        {
          state: {
            previous: pathname
          }
        }
      );
    }
  }
}

function HighlightedResult({
  searchValue,
  title
}: {
  searchValue: string
  title: string
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const result = boldString(title, searchValue);

  return result ? (
    <Typography
      color={ theme.customColors.textDisabled }
      lineHeight={ 1 }
      fontWeight={ 700 }
      textTransform={ "capitalize" }
      sx={ {
        "b":{
          color: isMobile
            ? theme.customColors.textPrimary
            : theme.palette.common?.black
        }
      } }
      dangerouslySetInnerHTML={ {
        __html: DOMPurify.sanitize(result)
      } }
    >
    </Typography>
  ) : (
    <Typography
      color={ theme.customColors.textDisabled }
      lineHeight={ 1 }
      fontWeight={ 700 }
    >
      { title }
    </Typography>
  );
  function boldString(str:string, substr:string) {
    return str.toLocaleLowerCase()?.replace(substr, `<b>${substr}</b>`);
  }


}

function ChatbotDrawer({
  showChatBot,
  hideChatbot
} : {
  showChatBot: boolean
  hideChatbot: () => void
}) {
  const theme = useTheme();

  return (
    <Drawer
      anchor="bottom"
      open={ showChatBot }
      className={ "chatBot-drawer" }
      onClose={ hideChatbot }
      sx={ {
        "& .MuiDrawer-paper": {
          background:"transparent",
          borderRadius:"4px",
          boxShadow:"none",

          bottom:"50px",
          left:"auto",
          right:"50px",
          top:"auto",

          height:"calc(100% - 70px)",
          maxHeight:"575px",
          overflowY:"visible",
          width:"320px",
          zIndex:1400,

          [`${theme.breakpoints.down("sm")}`]:{
            bottom:"77.83px",
            left:0,
            right:0,

            margin:"0 auto"
          }
        }
      } }
    >
      <ChatBot onClose={ hideChatbot } />
    </Drawer>
  );
}

function SearchBox({
  searchValue,
  setDropdown,
  setSearchValue,
  showSearchResults
} : {
  setDropdown: Dispatch<SetStateAction<Dropdown | null>>
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  showSearchResults: boolean
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const latestSearch = useSearchStore((state) => state.latestSearch);
  const pushSearch = useSearchStore((state) => state.pushSearch);

  const { data: results } = useSearchPreviewQuery({ freeText: encodeURIComponent(searchValue) });

  return (
    <Box
      display="flex"
      flexGrow={ 1 }
      justifyContent="center"
    >
      <Search>
        <SearchIconWrapper
          onClick={ () => {
            getSearchResults();
            setDropdown(null);
            setNavbarItem(NAVBAR_ITEMS.NONE);
          } }
        >
          <SearchIcon icon={ "Icons_search" } size={ 20 }/>
        </SearchIconWrapper>
        <SearchInputBase
          value={ searchValue }
          onChange={ (changeEvent) => {
            setDropdown(DROPDOWN.SEARCH);
            setSearchValue(changeEvent.target.value);
          } }
          inputProps={ { "aria-label": "search" } }
          onClick={ () => { setDropdown(DROPDOWN.SEARCH) } }
          onKeyUp={ (keyboardEvent) => {
            if(keyboardEvent.code === "Enter") {
              setDropdown(null);
              pushSearch({
                id: null,
                learningObjectType: null,
                learningObjectTypology: null,
                title: (keyboardEvent.target as HTMLInputElement).value
              });
              navigate(
                `/search/${encodeURIComponent((keyboardEvent.target as HTMLInputElement).value)}`,
                { state: { previous: pathname } }
              );
              setNavbarItem(NAVBAR_ITEMS.NONE);
            }
          } }
          placeholder={ `${t("what_to_learn")}?` }
        />
        <CancelIconWrapper
          aria-label="toggle password visibility"
          onClick={ () => {
            setDropdown(null);
            setSearchValue("");
          } }>
          {
            searchValue ? (
              <CancelIcon
                icon={ "close" }
                size={ "20px" }
              />
            ) : null
          }
        </CancelIconWrapper>
        {
          <ResultsStack
            className="scrollable"
            $isVisible={ showSearchResults }
            padding={ theme.spacing(2.5, 2) }
            gap={ 2 }
          >
            {
              (searchValue.length == 0 && latestSearch?.length == 0) ? (
                <Typography
                  color={ theme.palette.text?.secondary }
                  fontSize="0.75rem"
                  fontWeight={ "700" }
                  lineHeight={ 1 }
                >
                  { t("no_recent_search") }
                </Typography>
              ) : null
            }
            {
              searchValue.length == 0 && latestSearch?.length > 0 ? (
                <Typography
                  color={ theme.palette.text?.secondary }
                  fontSize="0.75rem"
                  fontWeight={ "700" }
                  lineHeight={ 1 }
                >
                  { t("latest_research") }
                </Typography>
              ) : null
            }
            {
              searchValue.length == 0 ? (
                latestSearch?.map(({
                  id,
                  learningObjectType,
                  learningObjectTypology,
                  title
                }, idx) => (
                  <li key={ `search-result--${idx}` }>
                    <Link
                      href={
                        Number(id) > 0 ?
                          `/esplora/dettaglio/${id}/${learningObjectTypology}` : `/search/${encodeURIComponent(title)}`
                      }
                      state={ {
                        previous: pathname
                      } }

                    >
                      <Box
                        display={ "flex" }
                        flexDirection={ "column" }
                        gap={ 1 }
                        onClick={ () => {
                          setDropdown(null);
                          setSearchValue(title);
                          pushSearch({
                            id,
                            learningObjectType: learningObjectType,
                            learningObjectTypology,
                            title: title
                          });
                          setNavbarItem(NAVBAR_ITEMS.EXPLORE);
                        } }
                        sx={ {
                          cursor: "pointer",

                          ":hover": {
                            boxShadow:
                              /* eslint-disable max-len */
                              `0 0 20px ${theme.palette.primary?.main && alpha(theme.palette.primary?.main, 0.15)},
                              inset 0 0 40px ${theme.palette.primary?.main && alpha(theme.palette.primary?.main, 0.15)}`
                            /* eslint-enable max-len */
                          }
                        } }
                      >
                        {
                          (learningObjectType !== null && learningObjectTypology !== null) &&
                            <TypeLevel
                              type={ learningObjectType as ContentType }
                              learningObjectTypology={ learningObjectTypology as Level }
                            />
                        }
                        <Typography
                          color="black"
                          lineHeight={ 1 }
                          fontWeight={ 700 }
                        >
                          { title }
                        </Typography>
                      </Box>
                    </Link>
                  </li>
                ))
              ) : null
            }
            {
              (searchValue.length > 0 && results?.length == 0) ? (
                <Typography
                  color={ theme.palette.text?.secondary }
                  fontSize="0.75rem"
                  fontWeight={ "700" }
                  lineHeight={ 1 }
                >
                  { t("no_results") + `"${searchValue}"` }
                </Typography>
              ) : null
            }
            { results && results?.length > 0 ?
              <ul className="block">{
                results?.map(({
                  id,
                  learningObjectType,
                  title,
                  type
                }, idx) => (
                  <li key={ `search-result--${idx}` }>

                    <Link
                      href={ `/esplora/dettaglio/${id}/${type}` }
                      state={ {
                        previous: pathname
                      } }
                    >
                      <Box
                        display={ "flex" }
                        flexDirection={ "column" }
                        gap={ 1 }
                        onClick={ () => {
                          setDropdown(null);
                          setSearchValue(title);
                          pushSearch({
                            id,
                            learningObjectType: learningObjectType,
                            learningObjectTypology: type,
                            title: title
                          });
                          setNavbarItem(NAVBAR_ITEMS.EXPLORE);
                        } }
                        sx={ {
                          cursor: "pointer",

                          ":hover": {
                            boxShadow:
                          /* eslint-disable max-len */
                          `0 0 20px ${theme.palette.primary?.main && alpha(theme.palette.primary?.main, 0.15)},
                          inset 0 0 40px ${theme.palette.primary?.main && alpha(theme.palette.primary?.main, 0.15)}`
                            /* eslint-enable max-len */
                          }
                        } }
                      >
                        <TypeLevel
                          type={ learningObjectType }
                          learningObjectTypology={ type }
                        />
                        <HighlightedResult
                          searchValue={ searchValue }
                          title={ title }
                        />
                      </Box>
                    </Link>
                  </li>
                ))
              } </ul>
              : null }
          </ResultsStack>
        }
      </Search>
    </Box>
  );

  function getSearchResults () {
    if (searchValue.length > 0){
      pushSearch({
        id: null,
        learningObjectType: null,
        learningObjectTypology: null,
        title: searchValue
      });
      navigate(
        `/search/${searchValue}`,
        {
          state: {
            previous: pathname
          }
        }
      );
    }
  }
}

function NotificationsIcon({
  showNotifications,
  setAnchorElNotify, // not need for mobile
  setDropdown
}: {
  showNotifications: boolean
  setAnchorElNotify?: Dispatch<SetStateAction<HTMLElement | null>>
  setDropdown: Dispatch<SetStateAction<Dropdown | null>>
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data: userNotifications } = useNotificationQuery({});

  return (
    <Button
      title= { t("notify_text") }
      disableRipple
      color={ "inherit" }
      aria-describedby={ showNotifications ? "simple-popover" : undefined }
      onClick={ (clickEvent) => {
        showNotifications && setDropdown(null);
        showNotifications || setDropdown(DROPDOWN.NOTIFICATIONS);
        setAnchorElNotify && setAnchorElNotify(clickEvent.currentTarget);
      } }
      sx={ {
        minWidth:"unset",
        padding: "0",
        position:"relative",
        zIndex: theme.zIndex.fab,

        "&:before": {
          background: theme.customColors.accentVariantD,
          borderRadius:"50%",
          boxShadow:`0px 0px 8px ${theme.customColors.accentVariantD}`,
          content: userNotifications?.some((notification) => notification.isRead === "N") ? "''" : "",
          position:"absolute",
          right:"1px",
          top:"2px",

          height: "5px",
          width:"5px"
        },

        "&:hover": {
          background: "transparent",
          boxShadow: "none"
        },

        ".icon path": {
          boxShadow: showNotifications
            ? `0px 0px 8px ${theme.customColors.accentVariantD}`
            : "none",
          fill: showNotifications
            ? theme.customColors.accentVariantD
            : theme.customColors.backgroundSecondary
          /* al posto di backgroundSecondary andrebbe un system bianco per entrambe le palette */
        }
      } }
    >
      <Icon
        icon={ "Icons_Notifiche" }
        width={ "16px" }
        height={ "20px" }
      />
    </Button>
  );
}

function ProfileIcon() {
  const theme = useTheme();
  const { pathname } = useLocation();

  const userData = useAuthStore(state => state.userData);

  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  const { data: profileImage  } = useGetProfileImageQuery({});

  const userAvatar = getUserAvatar(profileImage, userData);

  return (
    <Link
      href="/profile"
      onClick={ ()=> { setNavbarItem(NAVBAR_ITEMS.NONE) } }
      zIndex={ theme.zIndex.fab }
    >
      <Avatar
        alt={ userAvatar.initials }
        { ...stringAvatar(userAvatar.initials) }
        src={ userAvatar.src }
        sx={ {
          // eslint-disable-next-line max-len
          border: pathname.includes("/profile")
            ? `2px solid ${theme.customColors.systemSecondary01}`
            : undefined
        } }
      />
    </Link>
  );

}

function MenuIcon({
  showDropdownMenu,
  setAnchorElDropdown,
  setDropdown
} : {
  showDropdownMenu: boolean,
  setAnchorElDropdown: Dispatch<SetStateAction<HTMLElement | null>>
  setDropdown: Dispatch<SetStateAction<Dropdown | null>>
}) {
  const theme = useTheme();

  return (
    <Tooltip title="Open settings">
      <IconButton
        onClick={ (clickEvent) => {
          setAnchorElDropdown(clickEvent.currentTarget);
          showDropdownMenu && setDropdown(null);
          showDropdownMenu || setDropdown(DROPDOWN.MENU);
        } }
        sx={ {
          color: theme.customColors.systemPrimary01,
          p: 0,
          transform: showDropdownMenu ? " rotate(180deg)" : " rotate(0deg)",
          transition: "transform .4s linear"
        } }
      >
        <Icon
          icon={ "dropdown" }
          width={ "20px" }
          height={ "16px" }
        />
      </IconButton>
    </Tooltip>
  );
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip
}));

function DropdownMenu({
  anchorElDropdown,
  hideMenu,
  isMultilanguage,
  setLanguageModalOpen,
  showChatBot,
  showDropdownMenu,
  showTutorialUserMenu
} : {
  anchorElDropdown: HTMLElement | null
  hideMenu: () => void
  isMultilanguage:boolean
  setLanguageModalOpen:Dispatch<SetStateAction<boolean>>
  showChatBot: () => void
  showDropdownMenu: boolean
  showTutorialUserMenu?: boolean
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const authenticationMode = useAuthStore(state => state.mode);
  const language = useLanguageStore(state => state.language);
  const session = useAuthStore(state => state.session);
  const sessionToken = useAuthStore(state => state.sessionToken);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const userData = useAuthStore(state => state.userData);
  const userInfo = useAuthStore(state => state.userInfo);
  const postInitiativeId = useAuthStore(state => state.postInitiativeId);
  const signOut = useAuthStore(state => state.signOut);
  const localUser = localStorage.getItem("authState"); 
  const localUsername = localUser && JSON.parse(localUser || "")?.user.username;
  const isSSO = localUser && JSON.parse(localUser || "")?.mode === "SSO";
  const isImpersonation = isSSO ? localUsername?.toLowerCase().split("_")[1] !== userData?.external_id.toLowerCase() :  localUsername?.toLowerCase() !== userData?.external_id.toLowerCase();
  
  const inititiveUrl =  smartConfiguration?.urlDomain
    ? `${smartConfiguration?.urlDomain}.${smartConfiguration.urlCorporateDomain}`
    : smartConfiguration?.urlCorporateDomain
      ?  `${smartConfiguration?.urlCorporateDomain}`
      : window.location.host;

  const initiatives = useMemo(
    () => userInfo?.initiatives?.flatMap((domain) => (
      (domain.id.toString() !== userData?.initiative_id?.toString()) ? [{
        icon: "Icons_modulo-completato",
        id: domain.id,
        label: domain.label,
        link: "",
        type: "domain"
      }] : []
    )),
    [userData?.initiative_id, userInfo?.initiatives]
  );

  const isAdmin = userData?.permissions?.some(permission => (
    permission === PERMISSIONS["admin.dashboard.access"]
  ));
  const menuItems = getMenuItems();

  function deleteCookie(cname: string) {
    document.cookie =
    cname + "=delete;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
  }

  function cleanAllCookie() {
    const cookies = document.cookie.split(";");
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  return (
    <StyledMenu
      id="menu-appbar"
      anchorEl={ anchorElDropdown }
      anchorOrigin={ {
        horizontal: 20,
        vertical: "bottom"
      } }
      classes={ { paper:"scrollable" } }
      className={ "profile-popOver" }
      transformOrigin={ {
        horizontal: "right",
        vertical: -10
      } }
      open={ showDropdownMenu }
      onClose={ hideMenu }
      sx={{
        ".MuiPaper-root": {
          top: !!showTutorialUserMenu ? "70px !important" : "0"
        }
      }}
    >
      {
        (isMobile && smartConfiguration?.chatbot) ? (
          <MenuItem
            onClick={ ()=> {
              showChatBot();
              hideMenu();
            } }
            sx={ {
              gap: 2,
              border: !!showTutorialUserMenu ? "1px solid #A6FF03" : "0",
              borderRadius: !!showTutorialUserMenu ? "8px" : "0"
            } }
          >
            <ChatBotIcon>
              <img
                alt="chatBot"
                src={ chatBotIcon }
              />
            </ChatBotIcon>
            { t("chatBot_menu_label") }
          </MenuItem>
        ) : null
      }
      {
        initiatives?.map((initiative, index) => (
          <MenuItem
            key={ index }
            sx={ {
              margin: theme.spacing(0, 2),
              padding: theme.spacing(2, 0),
              textTransform: "capitalize",

              ">a, p": {
                alignItems: "center",
                color: theme.customColors.textPrimary,
                display: "flex",
                fontWeight: 500,
                gap: 2,
                textAlign: "center",
                textDecoration: "none"
              }
            } }>
            <Typography
              textAlign="center"
              onClick={ (e) => {
                e.preventDefault();
                hideMenu();
                if (
                  initiative.id
                  && session
                  && sessionToken
                  && userData?.organization_id
                ) {
                  document.cookie = `tutorial-completed-${sessionToken}` + "=delete;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
                  postInitiativeId(initiative.id);
                }
              } }
            >
              <Icon
                color={ theme.customColors.systemPrimary01 }
                icon={ initiative.icon }
                size={ 20 }
              />
              { `${t("switch_to")} ${initiative.label}` }
            </Typography>
          </MenuItem>
        ))
      }
      {
        menuItems.map((menuItem, index) => (
          <MenuItem
            key={ index }
            sx={ {
              margin: theme.spacing(0, 2),
              padding: theme.spacing(2, 0),
              textTransform: "capitalize",

              ">a, p": {
                alignItems: "center",
                display: "flex",
                gap: 2,

                color: theme.customColors.textPrimary,
                fontWeight: 500,
                textAlign: "center",
                textDecoration: "none"
              },

              "&:last-child": {
                borderTop: `1px solid ${theme.customColors.border}`
              }
            } }
          >
            {
              menuItem.label === "logout" ? (
                <Typography
                  textAlign="center"
                  onClick={ (e) => {
                    e.preventDefault();
                    cleanAllCookie();
                    hideMenu();
                    signOut();
                  } }

                > <Icon color={ theme.customColors.systemPrimary01 } icon={ menuItem.icon } size={ 20 }/>
                  { t(menuItem.label) }
                </Typography>
              ) : language && menuItem.label === "language" ? (
                <Typography
                  textAlign="center"
                  onClick={ (e) => {
                    e.preventDefault();
                    hideMenu();
                    setLanguageModalOpen(true);
                  } }
                >
                  <Icon color={ theme.customColors.systemPrimary01 } icon={ menuItem.icon } size={ 20 }/>
                  { `${ language.label} (${language.id?.toUpperCase()})` }
                </Typography>
              ) : (
                <Link
                  href={ menuItem.link }
                  onClick={ () => {
                    if (menuItem.label === "switch_admin") {
                      deleteCookie("activeLangCode");
                    }
                  }}
                  target={ menuItem.target }
                  textAlign="center"
                  rel={ "noreferrer" }

                > <Icon color={ theme.customColors.systemPrimary01 } icon={ menuItem.icon } size={ 20 }/>
                  { t(menuItem.label) }
                </Link>
              )
            }
          </MenuItem>
        ))
      }
    </StyledMenu>
  );

  function getMenuItems() {
    const menuItems = [];

    const admin = {
      icon: "Icons_modulo-completato",
      label: "switch_admin",
      link: `${adminLink}?sessionId=${sessionToken}&mode=${authenticationMode}&initiativeUrl=${inititiveUrl}`, // ?
      target: "_self",
      type: "link"
    };

    const multiLanguage = {
      icon: "globo",
      label: "language",
      link: "",
      target: "_self",
      type: "link"
    };

    const logOut = {
      icon: "Icons_log-out",
      label: "logout",
      link: "",
      target: "_self",
      type: "link"
    };

    if (isAdmin && !isImpersonation) { menuItems.push(admin) }
    if (isMultilanguage) { menuItems.push(multiLanguage)}
    menuItems.push(logOut);

    return menuItems;
  }
}

const HeaderAppBar = styled("header")<{
  $mobileSearchOpen: boolean
  $showNotifications?: boolean
}>(({
  theme,
  $mobileSearchOpen,
  $showNotifications=false
}) => ({
  backgroundColor: theme.customColors?.backgroundPrimaryLighterAlternative,
  minHeight: "80px",
  padding: "12px 0",
  // pointerEvents: "none",
  position: "sticky",
  zIndex: $mobileSearchOpen ? "unset" : "1300",

  [theme.breakpoints.down("sm")]: {
    minHeight: "60px",
    padding: "10px 0",
    zIndex: !$showNotifications ? theme.zIndex.modal : $mobileSearchOpen ? "unset": theme.zIndex.snackbar
  }
}));

function LanguageSelector({
  setOpenLanguageModal
} : {
  setOpenLanguageModal: Dispatch<SetStateAction<boolean>>
}) {
  const theme = useTheme();
  const language = useLanguageStore(state => state.language);

  return (
    <Stack
      alignItems="flex-end"
      flexGrow={ 1 }
      zIndex={ theme.zIndex.fab }
    >
      {language && <Typography
        textAlign="center"
        onClick={ () => {
          setOpenLanguageModal(true);
        } }
        sx={ {
          alignItems: "center",
          cursor: "pointer",
          display: "flex",
          gap: 1,
          textAlign: "center"
        } }
      >
        <Icon
          color={ theme.customColors.systemPrimary01 }
          icon={ "globo" }
          size={ 25 }
        />
        { `${ language.label} (${language.id?.toUpperCase()})` }
      </Typography>}
    </Stack>
  );
}

const StyledSpan = styled("span")(({ theme }) => ({
  background: "transparent",

  position: "absolute",
  zIndex: theme.zIndex.mobileStepper,

  height: "100%",
  top: 0,
  width: "100%"
}));

function CloseDropdowns({
  closeDropdowns
} : {
  closeDropdowns: () => void
}) {
  return (
    <StyledSpan onClick={ closeDropdowns }></StyledSpan>
  );
}

function LinkLogo({ authenticated }: { authenticated: boolean }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);

  const { digitedLogo, digidetLogoMobile, digitedLogoHeight, mobileLogoHeight } = getEnvironmentVariables();

  let logoHeight = "32px";
  if (isMobile){
    logoHeight = mobileLogoHeight ? mobileLogoHeight + "px" : "32px";
  }else{
    logoHeight = digitedLogoHeight ? digitedLogoHeight + "px" :
      smartConfiguration?.logoHeight ? smartConfiguration.logoHeight + "px" : "32px";
  }

  return (
    <Link
      href={ authenticated ? "/per-te" : "/logout" }
      onClick={ ()=> { authenticated && setNavbarItem("FOR_YOU") } }
      zIndex={ theme.zIndex.fab }
    >
      <Logo
        alt="logo"
        width="auto"
        // eslint-disable-next-line max-len
        height={ logoHeight }
        src={ isMobile ? digidetLogoMobile ?? logoMobileFallback : digitedLogo ?? logoFallback }
      />
    </Link>
  );
}
