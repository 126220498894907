import {
  Box,
  BoxProps,
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  alpha,
  styled,
  useTheme
} from "@mui/material/styles";
import {
  forwardRef,
  PropsWithChildren,
  RefObject
} from "react";
import { Icon } from "../../components/Icon";

export type ChipProps =
  & Omit<
    MuiChipProps,
    | "component"
    | "name"
  >
  & {
    isProfile?:boolean,
    isDruggable?:boolean
    active:boolean
    $cardVersion?:boolean
    description?:string
    isDisabled:boolean
    name: string
    isFirst?:boolean
    learningObjectTypology?: number
    onClickIcon?:()=> void  
  }

export const DecorationBox = styled(Box)<
  BoxProps & {
    $fill?: boolean
    height?: number
  }
>(({
  $fill=false,
  height=0.8,
  theme
}) => {
  return {
    backgroundColor: $fill ? theme.palette.primary?.main : undefined,
    border: `${theme.spacing(height / 4)} solid ${theme.palette.primary?.main}`,
    borderRadius: theme.spacing(height / 2),
    flexGrow: 1,
    height: theme.spacing(height)
  };
});

export const IconDraggableChip = styled(Box)<BoxProps>(({ theme })=> ({
  alignItems:"center",
  background:theme.customColors.backgroundSecondary,
  borderRadius:"100%",
  cursor: "pointer",
  display:"flex",
  height:"34px",
  justifyContent:"center",
  position:"absolute",
  right:"-16px",
  top:"-16px",
  width:"32px"
}));

export const Chip = forwardRef(
  function (

    props: ChipProps,
    ref
  ) {
    const { $cardVersion = false } = props;
    return (
      <MuiChip
        ref={ ref as RefObject<HTMLDivElement> }
        component={ $cardVersion ? CapabilitiesDecoratedBox :  DecoratedBox }
        sx={ {
          ...props.sx
        } }
        { ...props }
      />
    );
  }
);
Chip.displayName = "Chip";

type DecoratedBoxProps =
  PropsWithChildren<
    BoxProps & {
      isProfile?:boolean,
      isDruggable?:boolean,
      active:boolean
      cover?:string
      name?:string
      description?:string
      isDisabled:boolean
      isFirst?:boolean
      learningObjectTypology?: number
      onClickIcon?:()=> void    
    }
  >

export const DecoratedBox = forwardRef(
  function(
    props: DecoratedBoxProps,
    ref
  ) {
    const { 
      isProfile,
      isDruggable = true,
      active, 
      children, 
      isDisabled, 
      learningObjectTypology, 
      name,
      onClickIcon 
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
      <Box
        title={ name }
        ref={ ref as RefObject<HTMLDivElement> }
        display="flex"
        flexDirection="column"
        height="auto"
        gap={ 0.5 }
        paddingX={ 1 }
        paddingY={ 1 }
        width={ isProfile ? "fit-content" : "144px" }
        sx={ {
          backgroundColor: isProfile ? 
            theme.customColors.backgroundSecondary : isDisabled ? theme.customColors.backgroundDisabled : 
              alpha(theme.customColors.backgroundSecondary, 0.15),
          border:`0.5px solid ${ isDisabled ? theme.customColors.systemDisabled :  theme.customColors.borderTag}`,
          borderRadius:theme.spacing(1),
          color: isProfile ? theme.customColors.textInput : 
            isDisabled ?theme.customColors.systemDisabled : theme.customColors.textPrimary,
          cursor: isDisabled ? "not-allowed" : "pointer",
          marginTop: isDruggable ? isMobile ? "18px": "8px" : 0,
          minHeight:"56px",
          overflow:"visible",
          position:"relative",

          "span":{
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "2",
            display: "-webkit-box",
            fontSize:isMobile ? "0.75rem !important" : "1rem !important",
            lineHeight:"initial",
            maxWidth:"100%",
            overflow: "hidden",
            overflowWrap:"break-word",
            padding:0,
            textAlign:"center",
            textOverflow: "ellipsis",
            whiteSpace:"initial"
          }
        } }
        { ...props }
      >

        {
          isDruggable ? 
            <IconDraggableChip 
              onClick={ ()=> { 
                if(onClickIcon && active){
                  onClickIcon();
                }} }
              sx={ {
                background:isDisabled ? theme.customColors.backgroundDisabled : undefined,
                border:`0.5px solid ${ isDisabled ? theme.customColors.systemDisabled :  undefined}`
              } }
            >
              <Icon 
                color={ isDisabled ? theme.customColors.systemDisabled :theme.customColors.systemPrimary02 }
                icon={ active ?  "close" : "layer_2" } 
                size={ 20 }/>
            </IconDraggableChip> : null
        }
       
        { children }
 
        { (learningObjectTypology != undefined) ? (
          <Stack
            direction="row"
            gap={ 1 }
            width={ isMobile ? "8rem" : "10rem" }
          >
            {
              Array.from(Array(5).keys()).map((val) => (
                <DecorationBox
                  key={ val.toString() }
                  $fill={ (val <= learningObjectTypology) }
                  height={ isMobile ? 0.7 : undefined }
                />
              ))
            }
          </Stack>
        ) : null }
      </Box>
    );
  }
);

export const CapabilitiesDecoratedBox = forwardRef(
  function(
    props: DecoratedBoxProps,
    ref
  ) {
    const {  
      children, 
      cover, 
      description,
      isFirst
    } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));


    return (
      <Box
        ref={ ref as RefObject<HTMLDivElement> }
        display="flex"
        flexDirection="column"
        justifyContent={ "space-between" }
        height={ isMobile ? "212px ":  "267px" }
        gap={ 0.5 }
        paddingX={ 2 }
        paddingY={  3 }
        width={ isMobile ? "160px":  "200px" }
        sx={ {
          background:`${theme.linearGradients.gradientC},url(${cover})`,
          backgroundRepeat:"no-repeat",
          backgroundSize:"cover",
          border: `1px solid ${ isFirst ? theme.customColors.accentVariantB : theme.customColors.borderTag}`,
          borderRadius:theme.spacing(1),
          cursor: "pointer",
          overflow:"visible",
          padding:theme.spacing(3,2),
          position:"relative",

          "span, p":{
            "-webkit-box-orient": "vertical",
            display: "-webkit-box",
            maxWidth:"100%",
            overflow: "hidden",
            overflowWrap:"break-word",
            padding:0,
            textAlign:"center",
            textOverflow: "ellipsis",
            whiteSpace:"initial"
          },


          "span":{
            "-webkit-line-clamp": "2",
            fontSize:isMobile ? theme.spacing(2) : theme.spacing(2.5),
            fontWeight:700
          }
        } }
        { ...props }
      >
        {
          description ?  
            <Typography
              fontSize={ isMobile ? theme.spacing(1.5) : theme.spacing(1.75) }
              lineHeight={ "18px" }
              order={ 1 }
              sx={ {
                "-webkit-line-clamp": "6" 
              } }
            >{ description }</Typography> : 
            null
        }
       
        { children }
      </Box>
    );
  }
);
CapabilitiesDecoratedBox.displayName = "DecoratedBox";
DecoratedBox.displayName = "DecoratedBox";
