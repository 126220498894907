import { Box, IconButton, Modal, Typography } from "@mui/material";
import { useTheme  } from "@mui/material/styles";
import { style } from "./style";
import { ModalSessionDetailProps } from "./type";
import { Icon } from "../../components";
import SessionAccordionCst from "../../components/SessionAccordionCst";

        
export function ModalSessionDetail({ open, handleClose, title, size, data, isVirtual }: ModalSessionDetailProps) {
  const theme = useTheme();

  return (
    <Modal
      open={ open }
      onClose={ handleClose }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={ {
        zIndex: 1400
      } }
    >
      <Box
        sx={ {
          bgcolor: theme.customColors.backgroundPrimaryLighter,
          border: `2px solid ${ theme.customColors.border }`,
          ...style,
          ...size,
          position: "relative",
          height: "90svh",
          overflow: "auto"
        } }
      >
        <Box sx={ {
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          p: "1.5rem",
          zIndex: 2,
          bgcolor: theme.customColors.backgroundPrimaryLighter
        } }>
          <Typography
            id="modal-modal-title"
            variant="h5"
            sx={ {
              fontWeight: 700
            } }>
            { title }
          </Typography>
          <IconButton
            onClick={ handleClose }
          >
            <Icon
              icon={ "close" }
              width={ "20px" }
              height={ "16px" }
            />
          </IconButton>
        </Box>
        <Box sx={ { my: 4, px: "1.5rem" } }>
          { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ }
          { data?.map((session: any, index: number) => (
            <SessionAccordionCst key={ session.id } isDetail={ true } data={ session } index={ index } isVirtual={ isVirtual } />
          )) }
        </Box>
      </Box>
    </Modal>
  );
}
