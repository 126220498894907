import { QueryKey, useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { CAPABILITY_TYPE, ERRORS, LEARNING_LEVEL } from "../consts";
import { fetcherFactory } from "../services";
import { useAuthStore, useCoverStore, useCoverProfessionStore, useLanguageStore } from "../stores";
import { getEnvironmentVariables } from "../utils/general";


export type OnBoardingItemType = {
        corporate_id: number,
        cover:string, // TO MOCK RANDOM
        description: string,
        id: number,
        identifier: string,
        industries:number[]
        name: string,
        obsolete: boolean,
}

export type ResponseType = {
      metadata: {
        total_elements: number
        total_pages: number,
      },
      output: OnBoardingItemType[],
}
export type knowledgeDomainType = {
  id:number,
  learning_level:keyof typeof LEARNING_LEVEL
}
export type capabilityType = {
  id:number,
  purpose:keyof typeof CAPABILITY_TYPE
}

const { basePath } = getEnvironmentVariables();

export function useUserProfessionsQuery({
  enabled = true,
  pageSize=6
} : {
  enabled?: boolean
  pageSize?: number
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userIndustry = useAuthStore(state => state.userInfo?.industry?.id);
  const covers = useCoverProfessionStore();
  const { language } = useLanguageStore(state => state);
  
  const fetcher = fetcherFactory();

  const uri = `/skills/${corporateId}/learner/professions`;
  
  return useInfiniteQuery<
  unknown,
    unknown,
    ResponseType,
    QueryKey
  >({
    enabled: Boolean(
      enabled
        && userIndustry
        && sessionToken
        && corporateId
    ),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as ResponseType).metadata)?.total_pages || 0;
      return (nextPage < totalPages) ? { nextPage } : undefined;
    },
    queryFn: ({ pageParam }) => fetcher(
      // eslint-disable-next-line max-len
      `${basePath}${uri}?page=${pageParam?.nextPage ?? 0}&page_size=${pageSize}&industries=${userIndustry}&obsolete=false&langCode=${language?.id}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ResponseType) => ({
        
        metadata: data.metadata,
        output:data.output.map((industry) => {
          industry.cover = covers.get();
          return industry;
        })
      }))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, pageSize]
  });
}

export function useUserJobsQuery({
  enabled = true,
  pageSize=6,
  professionId,
  professionName
} : {
  enabled?: boolean
  pageSize?: number
  professionId:number | undefined
  professionName:string
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userIndustry = useAuthStore(state => state.userInfo?.industry?.id) ?? "";
  const { language } = useLanguageStore(state => state);
  
  const fetcher = fetcherFactory();

  const uri = `/skills/${corporateId}/learner/professions/${professionId}/jobs`;
  
  return useInfiniteQuery<
  unknown,
    unknown,
    ResponseType,
    QueryKey
  >({
    enabled: Boolean(
      enabled
        && corporateId
        && professionId
        && sessionToken
        && userIndustry
    ),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as ResponseType).metadata)?.total_pages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      // eslint-disable-next-line max-len
      `${basePath}${uri}?name=${professionName}&page=${pageParam?.nextPage ?? 0}&page_size=${pageSize}&industries=${userIndustry}&obsolete=false&langCode=${language?.id}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ResponseType) => (data))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, professionName, pageSize]
  });
}

export function useUserJobsAreasQuery({
  enabled = true,
  jobId,
  pageSize=6
} : {
  enabled?: boolean
  jobId:number | null
  pageSize?: number | ""
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userIndustry = useAuthStore(state => state.userInfo?.industry?.id) ?? "";
  const { language } = useLanguageStore(state => state);
  
  const fetcher = fetcherFactory();

  const uri = `/skills/${corporateId}/learner/knowledge-areas`;
  
  return useInfiniteQuery<
  unknown,
    unknown,
    ResponseType,
    QueryKey
  >({
    enabled: Boolean(
      enabled
        && jobId
        && corporateId
        && sessionToken
        && userIndustry
    ),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as ResponseType).metadata)?.total_pages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      // eslint-disable-next-line max-len
      `${basePath}${uri}?page=${pageParam?.nextPage ?? 0}&page_size=${pageSize}&industries=${userIndustry}&job=${jobId}&obsolete=false&langCode=${language?.id}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ResponseType) => (data))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, jobId, pageSize]
  });
}

export function useUserDomainsArea({
  enabled = true,
  job,
  knowledgeAreadId,
  pageSize=6
} : {
  enabled?: boolean
  job:IdleDeadline,
  knowledgeAreadId:number | null
  pageSize?: number | ""
}){


  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userIndustry = useAuthStore(state => state.userInfo?.industry?.id) ?? "";
  const { language } = useLanguageStore(state => state);
  
  const fetcher = fetcherFactory();

  const uri = `/skills/${corporateId}/learner/knowledge-areas/${knowledgeAreadId}/domains`;
  
  return useInfiniteQuery<
  unknown,
    unknown,
    ResponseType,
    QueryKey
  >({
    enabled: Boolean(
      enabled
        && corporateId
        && job
        && knowledgeAreadId
        && sessionToken
        && userIndustry
    ),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as ResponseType).metadata)?.total_pages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      // eslint-disable-next-line max-len
      `${basePath}${uri}?page=${pageParam?.nextPage ?? 0}&page_size=${pageSize}&job=${job}&obsolete=false&langCode=${language?.id}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ResponseType) => (data))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, pageSize]
  });
}
export function useUserDomainsPersonal({
  domainsSearched,
  enabled = true,
  pageSize=6
} : {
  domainsSearched:string,
  enabled?: boolean
  pageSize?: number | ""
}){


  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const { language } = useLanguageStore(state => state);
  
  const fetcher = fetcherFactory();

  const uri = `/skills/${corporateId}/learner/knowledge-areas/domains`;
  
  return useInfiniteQuery<
  unknown,
    unknown,
    ResponseType,
    QueryKey
  >({
    enabled: Boolean(
      enabled
        && domainsSearched?.length >=  3
        && corporateId
        && sessionToken
    ),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as ResponseType).metadata)?.total_pages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      // eslint-disable-next-line max-len
      `${basePath}${uri}?name=${domainsSearched}&page=${pageParam?.nextPage ?? 0}&page_size=${pageSize}&obsolete=false&langCode=${language?.id}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ResponseType) => (data))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, domainsSearched, pageSize]
  });
}

export function useUserCapabilitiesQuery({
  enabled = true,
  pageSize=6
} : {
  enabled?: boolean
  pageSize?: number
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userIndustry = useAuthStore(state => state.userInfo?.industry?.id) ?? "";
  const userPofessionalLevel = useAuthStore(state => state.userInfo?.professional_level) ?? "";
  const covers = useCoverStore();
  const { language } = useLanguageStore(state => state);

  const fetcher = fetcherFactory();

  const uri = `/skills/${corporateId}/learner/capabilities`;
  
  return useInfiniteQuery<
  unknown,
    unknown,
    ResponseType,
    QueryKey
  >({
    enabled: Boolean(
      enabled
        && userIndustry
        && sessionToken
        && corporateId
    ),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as ResponseType).metadata)?.total_pages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      // eslint-disable-next-line max-len
      `${basePath}${uri}?page=${pageParam?.nextPage ?? 0}&page_size=${pageSize}&obsolete=false&type=${userPofessionalLevel}&langCode=${language?.id}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ResponseType) => ({
        
        metadata: data.metadata,
        output:data.output.map((industry) => {
          industry.cover = covers.get();
          return industry;
        })
      }))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, pageSize]
  });
}

export function usePutPersonalKnowledge({
  knowledge
}: {
  knowledge:knowledgeDomainType[] | null
}) {

  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const body = {
    knowledge_domains:knowledge
  };
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}/usermgmt/users/self/knowledge-domains/personal`,
      {
        body: JSON.stringify(
          body
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "PUT"
      }
    )
      .then((res) => {
        if( res && res?.bodyUsed){
          return res.json();
        }
        else {
          return res;
        }
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      //DO SOMETHING ? 
    }
  });
}
export function usePutExecutiveKnowledge({
  knowledge
}: {
  knowledge:knowledgeDomainType[] | null
}) {

  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const body = {
    knowledge_domains:knowledge
  };
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}/usermgmt/users/self/knowledge-domains/executive`,
      {
        body: JSON.stringify(
          body
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "PUT"
      }
    )
      .then((res) => {
        if( res && res?.bodyUsed){
          return res.json();
        }
        else {
          return res;
        }
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      //DO SOMETHING ? 
    }
  });
}
export function usePutCapabilities({
  capabilities
}: {
  capabilities:capabilityType[] | null
}) {

  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const body = {
    capabilities:capabilities
  };
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}/usermgmt/users/self/capabilities`,
      {
        body: JSON.stringify(
          body
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "PUT"
      }
    )
      .then((res) => {
        if( res && res?.bodyUsed){
          return res.json();
        }
        else {
          return res;
        }
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      //DO SOMETHING ? 
    }
  });
}
export function useUpdateUserInfo({
  job,
  profession
}: {
  job:number |  null,
  profession:number | null
}) {

  const fetcher = fetcherFactory();
  const userIndustry = useAuthStore(state => state.userInfo?.industry?.id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const body = {
    industry:userIndustry,
    job:job,
    profession:profession
  };
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}/usermgmt/users/self`,
      {
        body: JSON.stringify(
          body
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "PUT"
      }
    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      //DO SOMETHING ? 
    }
  });
}
export async function updateUserInfo({
  authorization,
  industry,
  job,
  profession,
  sessionToken
} : {
  authorization: string
  industry:number
  job:Pick<OnBoardingItemType, "id" | "name"> | null
  profession:Pick<OnBoardingItemType, "id" | "name"> | null
  sessionToken: string
}): Promise<void> {
  const fetcher = fetcherFactory();
  const body = {
    industry:industry,
    job:job?.id,
    profession:profession?.id
  };
  return new Promise((resolve, reject)=> {
    fetcher(
      `${basePath}/usermgmt/users/self`,
      {
        body: JSON.stringify(
          body
        ),
        headers: {
          "Authorization": authorization,
          "Content-type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "PUT"
      }
    )
      .then(() => { resolve() })
      .catch(error => { reject(error) });
  });
}
