/* eslint-disable */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import { useTheme  } from "@mui/material/styles";
import dayjs from "dayjs";
import { Icon } from "../../components";


// Interface
interface SessionAccordionCstInterface {
  data: any;
  index: number;
  onEdit?: (data: any, index: number) => void;
  onDelete?: (data: any) => void;
  isVirtual?: boolean;
  isDetail?: boolean;
}

// eslint-disable-next-line no-empty-pattern
const SessionAccordionCst: React.FC<SessionAccordionCstInterface> = ({
  data,
  index,
  onEdit,
  onDelete,
  isVirtual,
  isDetail,
}) => {
  const theme = useTheme();

  const getArrayString = (array: any) => {
    if (!!array && !!array.length) {
      const newArray = array.map((item: any) => {
        return item.completeName;
      } );
      return newArray.join();
    } else {
      return "-";
    }
  };

  return (
    <Accordion
      sx={ {
        mt: "1.5rem",
        border: `1px solid white`,
        borderRadius: "4px",
        "&.Mui-disabled": {
          background: "transparent",
        },
      } }
    >
      <AccordionSummary
        expandIcon={
          <Icon
            icon={ "dropdown" }
            width={ "20px" }
            height={ "16px" }
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          minHeight: "3rem",
          pl: "1.875rem",
          "&.Mui-expanded": {
            minHeight: "3rem",
          },
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
          "&.Mui-disabled": {
            opacity: 1,
          },
        }}
      >
        Sessione {index + 1}
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            rowGap: "1rem",
            [theme.breakpoints.down("sm")]: {
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid white",
              p: "1rem",
            }}
          >
            <Typography fontWeight={600} mb={1}>
              Data Inizio
            </Typography>
            <Typography>
              {dayjs(data.start_date).format("DD-MM-YYYY h:mm A")}
            </Typography>
          </Box>
          <Box
            sx={{
              borderBottom: "1px solid white",
              p: "1rem",
            }}
          >
            <Typography fontWeight={600} mb={1}>
              Data Fine
            </Typography>
            <Typography>
              {dayjs(data.end_date).format("DD-MM-YYYY h:mm A")}
            </Typography>
          </Box>
          {!isVirtual && (
            <>
              <Box
                sx={{
                  borderBottom: "1px solid white",
                  p: "1rem",
                }}
              >
                <Typography fontWeight={600} mb={1}>
                  Sede
                </Typography>
                <Typography>
                  {data.location}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderBottom: "1px solid white",
                  p: "1rem",
                }}
              >
                <Typography fontWeight={600} mb={1}>
                  Aule
                </Typography>
                <Typography>{data.rooms.toString()}</Typography>
              </Box>
            </>
          )}
          <Box
            sx={{
              borderBottom: "1px solid white",
              p: "1rem",
            }}
          >
            <Typography fontWeight={600} mb={1}>
              Docenti
            </Typography>
            <Typography>{getArrayString(data.teachers)}</Typography>
          </Box>
          <Box
            sx={{
              borderBottom: "1px solid white",
              p: "1rem",
            }}
          >
            <Typography fontWeight={600} mb={1}>
              Tutor
            </Typography>
            <Typography>{getArrayString(data.tutors)}</Typography>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SessionAccordionCst;
